import axios from "axios";
const { VUE_APP_SHIELD_URL, VUE_APP_SPONSOR } = process.env;

export default class ShieldService {

  static async createStep(values, step, version, id) {
    try {
      var carroRes;
      var data = {};
      var lenght = 0;

      //Para el primer step del formulario
      if (step == 1) {
        //Para el primera interacción
        if (version == 1) {
          data = {
            name: values[0],
            email: values[1],
            phoneNumber: values[2],
          };
          const params = {
            sponsor: VUE_APP_SPONSOR,
            stepOne: data,
            lenght: 1,
          };

          carroRes = await axios.post(
            `${VUE_APP_SHIELD_URL}/step/create`,
            params
          );
        }
        //Para las demás interacciones interacción
        else {
          values.forEach((element) => {
            if (element != null) {
              lenght = lenght + 1;
            }
          });
          data = {
            name: values[0],
            email: values[1],
            phoneNumber: values[2],
          };
          const params = {
            sponsor: VUE_APP_SPONSOR,
            stepOne: data,
            id: id,
            lenght: lenght,
          };

          carroRes = await axios.put(
            `${VUE_APP_SHIELD_URL}/step/update`,
            params
          );
        }
      }
      //Para el segundo step del formulario
      else if (step == 2) {
        const params = {
          sponsor: VUE_APP_SPONSOR,
          steptwo: values,
          id: id,
          lenght: 8,
        };

        carroRes = await axios.put(`${VUE_APP_SHIELD_URL}/step/update`, params);
      }
      //Para el tercer step del formulario
      else if (step == 3) {
        values.forEach((element) => {
          if (element != null) {
            lenght = lenght + 1;
          }
        });
        lenght = lenght + 1;
        data = {
          species: values[0],
          breed: values[1],
          petName: values[2],
          petAge: values[3],
          sex: values[4],
          hasMicrochip: values[5],
        };
        const params = {
          sponsor: VUE_APP_SPONSOR,
          stepthree: data,
          id: id,
          lenght: lenght,
        };

        carroRes = await axios.put(`${VUE_APP_SHIELD_URL}/step/update`, params);
      }

      else if (step == 4) {
        values.forEach((element) => {
          if (element != null) {
            lenght = lenght + 1;
          }
        });
        lenght = lenght + 1;
        data = {
          name: values[0],
          surname: values[1],
          serialNumber: values[2],
          rut: values[3],
          region: values[4],
          commune: values[5],
          address: values[6],
          email: values[7],
          phoneNumber: values[8],
          species: values[9],
          breed: values[10],
          petName: values[11],
          petAge: values[12],
          sex: values[13],
          hasMicrochip: values[14],
        };
        const params = {
          sponsor: VUE_APP_SPONSOR,
          stepthree: data,
          id: id,
          lenght: lenght,
        };

        carroRes = await axios.put(`${VUE_APP_SHIELD_URL}/step/update`, params);
      }

      return carroRes;
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrió un error al crear el Step",
      };
    }
  }

  static async getPlans(code, age, unit) {
    if (unit === "M" && age < 10) {
      age = 0
    }
    else if (unit === "M" && age >= 10) {
      console.log("mes y mayor o igual a 10")
      age = 0
    }
    else if (unit === "Y" && age < 10) {
      console.log("año y menor q 10")
      age = 0
      console.log("aca123")
    }
    else if (unit === "Y" && age >= 10) {
      console.log("año y mayor  o igual q 10")
      console.log(age)
    }
    try {
      var url = `${VUE_APP_SHIELD_URL}/gallagher`;
      var params = "";
      if (code) {
        params = url + `?sponsor=${VUE_APP_SPONSOR}&code=${code}&age=${age}`;
      } else {
        params = url + `?sponsor=${VUE_APP_SPONSOR}&age=${age}`;
      }

      const shieldRes = await axios.get(
        params
      );

      return shieldRes.data.status === "ok" ? shieldRes.data.data : [];
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener los planes",
      };
    }
  }

  static async getPeople(personID) {
    try {
      const shieldRes = await axios.get(
        `${VUE_APP_SHIELD_URL}/person/get/${personID}`,
      );

      return shieldRes.data.status === "ok" ? shieldRes.data.data : [];
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener los planes",
      };
    }
  }

  static async createInsurance(person, pet, plan, stepId) {

    const jsonData = { person, pet, planId: plan._id, stepId: stepId };
    const shieldRes = await axios.post(
      `${VUE_APP_SHIELD_URL}/gallagher/createWithRunWayPayment`,
      jsonData
    );
    if (shieldRes.data.status === "ok") return shieldRes.data.data.link;
    else throw shieldRes.data;
  }

  static async createInsuranceWithToku(person, pet, plan, stepId) {

    var sponsorTokuRedirect = process.env.VUE_APP_SPONSOR_TOKU_REDIRECT

    const jsonData = { person, pet, planId: plan._id, stepId: stepId, sponsorTokuRedirect: sponsorTokuRedirect };

    const shieldRes = await axios.post(
      `${VUE_APP_SHIELD_URL}/gallagher/createWithToku`,
      jsonData
    );
    if (shieldRes.data.status === "ok") return shieldRes.data.data;
    else throw shieldRes.data;
  }
}
