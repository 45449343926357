<template>
  <v-container style="margin:auto">

    <NavBar :step="2"/>    

    <Titulo></Titulo>

    <br v-if="screen < 800">
    
    
    <br>
    <br>
    
    <v-col>
      <v-stepper v-model="e1" elevation="0">
      <v-stepper-header>
        <div class="circle-step">
          <v-stepper-step class="stepForms" style="flex-direction: column;" :color="stepperColor" :complete="e1 > 1" step="1"> Inicio
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 2" step="2"> Mascota
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step2">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 3" step="3"> Plan
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step2">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" step="4"> Datos </v-stepper-step>
        </div>
      </v-stepper-header>
      <br v-if="screen < 800">
      <br>
      <br>


        <v-stepper-items>
          <v-stepper-content step="3">

            <div class="step-data">

              <v-form ref="form" v-model="valid" max-witdh="300px">

                <h3 class="text-center" style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 18px; line-height: 15px; letter-spacing: -0.01em;
                    color: #002C77;">Estos son nuestros planes</h3>
                <h4 class="text-center"
                  style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 13px; line-height: 16px; color: #232323; opacity: 0.75; padding: 2%;">
                  Selecciona uno de los planes que tenemos para tu mascota</h4>
                

                <!-- <v-row>
                  &nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;
                <v-text-field :class="labelDescuento" v-model="userData.couponId"
                  label="Ingresa aquí tu código de descuento" maxlength="51" :rules="couponRules" solo></v-text-field>

                  <v-btn filled solo
                    style="width:70px; height: 50px; border-radius: 0px 10px 10px 0px; font-family: Helvetica; font-style: normal; font-weight: 700;
                      font-size: 14px; line-height: 143.34%; background-color:#FFB600; color:white; text-transform: capitalize ;"
                    @click="SendCouponID()">Aplicar</v-btn>

                </v-row> -->

              </v-form>

            </div>

            <br>
            <br>

            <div v-if="screen > 800 && loadingPlans == false && tipoPlan == false" class="plan-container">
              <PlanEscritorio v-for=" (plan, indice) in plans" :planSenior="planSenior" :key="plan._id" :planData="plan"
                :index="indice" :couponId="userData.couponId" :tipoPlan="tipoPlan" @next-step="validate" />
            </div>

            <div v-if="screen > 800 && loadingPlans == false && tipoPlan == true" class="plan-container">
              <PlanEscritorio v-for=" (plan, indice) in planSenior" :key="plan._id" :planData="plan" :index="indice"
                 :couponId="userData.couponId" :tipoPlan="tipoPlan" @next-step="validate" />
            </div>

            <div v-if="screen <= 800 && loadingPlans == false" class="plan-container">
              <PlanMovil :planData="plans" :planSenior="planSenior" :tipoPlan="tipoPlan" @next-step="validate" />
            </div>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>

    </v-col>
  </v-container>
</template>

<script>
import PlanEscritorio from "@/components/PlanEscritorio.vue";
import PlanMovil from "@/components/PlanMovil.vue";
import ShieldService from "../services/shield.service";

import Titulo from "../components/Titulo.vue";
import NavBar from "@/components/NavBar.vue";

import swal from 'sweetalert';

export default {
  name: "PlanForm",
  components: { PlanEscritorio, PlanMovil, Titulo, NavBar },
  data: () => ({
    plans: [],
    stepperColor: '#00968F',
    planSenior: [],
    e1: 3,
    loadingPlans: true,
    screen: window.screen.width,
    userData: {
      name: null,
      surname: null,
      serialNumber: null,
      rut: null,
      region: {
        name: null,
      },
      commune: {
        name: null,
      },
      address: null,
      email: null,
      phoneNumber: null,
      hasAcode: null,
      couponId: null,
      dates: null,
    },
    petData: {
      age: null,
      unit: null,
    },
    labelDescuento: '',
    tipoPlan: false,
    couponRules: [
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 carácteres"
    ],
  }),
  mounted() {

    if (this.screen > 800) {
      this.labelDescuento = 'labelDescuento'
    } else {
      this.labelDescuento = 'labelDescuento2'
    }

    this.petData.age = localStorage.getItem('ageQ')
    this.petData.unit = localStorage.getItem('ageU')
    this.getPlans();
  },
  methods: {
    async SendCouponID() {
    if (this.$refs.form.validate()) {
      console.log("HOLA")
      var plansResponse = await ShieldService.getPlans( //Espera el código ingresado en el step 1
        this.userData.couponId,
        this.petData.age,
        this.petData.unit
      );
      console.log(this.userData.couponId);
      console.log(plansResponse);
      if (plansResponse.length != 0) {
        this.plans = plansResponse
        var aux = ['', '', '']
        this.plans.forEach(plan => {
          if (plan.name == 'Plan S') {
            aux[0] = plan
          } else if (plan.name == 'Plan M') {
            aux[1] = plan
          } else if (plan.name == 'Plan L') {
            aux[2] = plan
          } else if (plan.name == 'Mascota Senior') {
            this.tipoPlan = true
            this.planSenior[0] = plan
          }
        });
        if (this.planSenior[0]) {
          this.plans = this.planSenior[0]
        } else {
          this.plans = aux
        }
        this.loadingPlans = false;
        this.$forceUpdate();
      } else {
        this.userData.couponId = null
        swal({
          title: "Código no válido",
          text: "Lamentablemente no existen planes asociados a este código de descuento. Intenta con otro código.",
          icon: "info",
          button: "Ok",
        });
      }
    }
},
back() {
    },
    validate(plan) {
      this.$emit("next-step", 2, plan);
    },
    async getPlans() {
      this.loadingPlans = true;
      this.petData.age = localStorage.getItem('ageQ')
      this.petData.unit = localStorage.getItem('ageU')
      this.plans = await ShieldService.getPlans(null, this.petData.age, this.petData.unit);
      var aux = ['', '', '']
      this.plans.forEach(plan => {
          if (plan.name == 'Plan S') {
          aux[0] = plan
        } else if (plan.name == 'Plan M') {
          aux[1] = plan
        } else if (plan.name == 'Plan L') {
          aux[2] = plan
        } else if (plan.name == 'Mascota Senior') {
          this.tipoPlan = true
          this.planSenior[0] = plan
        }
      });
      this.plans = aux
      this.loadingPlans = false;
    },
  },
};
</script>


<style lang="scss" scoped>
.plan-container {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}

.labelDescuento {
  width: 320px;
  height: 48px;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 10px 0 0 10px;
  margin: auto;
}

.labelDescuento2 {
  min-width: 30%;
  max-width: 73%;
  height: 48px;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 10px 0 0 10px;
  margin: auto;
}

#app > div > main > div > div > div.col > div.v-stepper.v-sheet.theme--light.elevation-0 > div.v-stepper__header {
  max-width: 80%;
}
</style>