<template>
  <v-container>

    <NavBar :step="1"/>    

    <Titulo></Titulo>

    
    <br v-if="screen < 800">
    <br>
    
    
    <v-stepper v-model="e1" elevation="0">
      <v-stepper-header>
        <div class="circle-step">
          <v-stepper-step class="stepForms" style="flex-direction: column;" :color="stepperColor" :complete="e1 > 1" step="1"> Inicio
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 2" step="2"> Mascota
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step2">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 3" step="3"> Plan
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step2">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" step="4"> Datos </v-stepper-step>
        </div>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="2">
          <v-form ref="form" v-model="valid">
            <v-container style="justify-content: center; display: flex; flex-direction: column; align-items: center;">
              <div class="form-container">

                <v-col style="max-width: 300px">
                  <h3 class="text-center"
                    style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 18px; line-height: 15px; letter-spacing: -0.01em; color: #002C77;">
                    Cuéntanos sobre tu mascota</h3>

                  <h4 class="text-center"
                    style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 13px; line-height: 16px; color: #232323; opacity: 0.75; padding: 2%;">
                    Selecciona una opción</h4>

                  <v-col>
                    <v-radio-group required v-model="petData.species" v-on:change="getBreeds()" @change="change()">
                      <v-radio v-for=" (option, index) in speciesOptions" :key="option.id" :value="option"
                        :class="mascotaVista" off-icon on-icon>
                        <template v-slot:label>
                          <div style="display: flex; flex-direction: column; justify-content: center;">
                            <img width="130px" :alt="option.name" :src="option.image" class="species-icon" />
                            <br>
                            <label style="margin:auto;" v-if="index == 0">Perro</label>
                            <v-radio-group v-if="index == 0" v-model="petSelect">
                              <v-radio v-model="selecPPet" style="margin: auto;"></v-radio>
                            </v-radio-group>
                            <label style="margin:auto;" v-if="index == 1">Gato</label>
                            <v-radio-group v-if="index == 1" v-model="catSelect">
                              <br>
                              <v-radio v-model="selecPPet" style="margin: auto;"></v-radio>
                            </v-radio-group>
                          </div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-col>

                  <div v-if="petData.species.id">

                    <v-col>
                      <v-text-field v-model="petData.name" maxlength="51" :rules="nameRules" label="Ingresa el nombre de tu mascota"
                        @change="change()" required  solo filled class="v-textField">
                      </v-text-field>
                    </v-col>

                    <br>

                    <v-col>
                      <v-select :disabled="!petData.species" :items="breeds" v-model="petData.breed" :rules="breedRules" return-object
                        @change="change()" item-text="name" label="Selecciona la raza" required  solo filled class="v-textField">
                      </v-select>
                    </v-col>

                    <br>

                    <v-col>
                      <v-select :items="ages" v-model="petData.age" :rules="ageRules" :loading="loadingAges" return-object
                        @change="change()" :disabled="loadingAges" item-text="label"
                        label="Selecciona la edad de tu mascota" required  solo filled class="v-textField">
                      </v-select>
                    </v-col>

                    <br>

                    <v-col>
                      <v-select :items="sexOptions" v-model="petData.sex" :rules="sexRules" item-text="label"
                        @change="change()" return-object label="Selecciona el géreno de tu mascota" required  solo filled class="v-textField">
                      </v-select>
                    </v-col>

                    <br>

                    <v-col>
                      <h3 class="text-center"
                        style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 18px; line-height: 15px; letter-spacing: -0.01em; color: #002C77;">
                        ¿Tiene microchip?</h3>

                      <h4 class="text-center"
                        style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 13px; line-height: 16px; color: #232323; opacity: 0.75; padding: 2%;">
                        No influye en el seguro, pero nos ayuda a recomendarte coberturas y servicios</h4>

                      <v-radio-group autofocus @change="change()" v-model="petData.hasMicrochip" :rules="confirmRules"
                        required row>
                        <v-radio value="true"><template v-slot:label>
                            <div class="forecast-selector">Si</div>
                          </template></v-radio>
                        <v-radio value="false"><template v-slot:label>
                            <div class="forecast-selector">No</div>
                          </template></v-radio>
                      </v-radio-group>

                    </v-col>

                    <v-col>

                      <div class="button-container">
                        <v-btn
                          style="background-color: rgba(0, 44, 119, 1); color: white; border-radius: 12px; width: 100%; height: 120%; margin: auto;"
                          @click="validate()">
                          Continuar
                        </v-btn>
                      </div>

                    </v-col>

                  </div>

                </v-col>
              </div>
            </v-container>
          </v-form>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

  </v-container>
</template>

<script>
import catIcon from "@/assets/images/catt2.png";
import dogIcon from "@/assets/images/dogg.png";

import catIcon2 from "@/assets/images/catt.png";
import dogIcon2 from "@/assets/images/dogg2.png";

import BreedsService from "../services/breeds.service";
import AgeService from "../services/age.service";
import ShieldService from "../services/shield.service";

import Titulo from "../components/Titulo.vue";
import NavBar from "@/components/NavBar.vue";

export default {
  name: "DetailForm",
  components: { Titulo, NavBar },
  data: () => ({
    stepperColor: '#00968F',
    petSelect: true,
    catSelect: true,
    valid: false,
    e1: 2,
    stepId: null,
    petData: {
      species: {
        name: null,
      },
      breed: null,
      breedCode: null,
      petName: null,
      age: null,
      sex: null,
      hasMicrochip: false,
    },
    screen: window.screen.width,
    sexOptions: [
      {
        id: "male",
        label: "Macho",
      },
      {
        id: "female",
        label: "Hembra",
      },
    ],
    selecPPet: false,
    breedRules: [(v) => !!v || "Necesitamos saber la raza de tu mascota"],
    nameRules: [
      (v) => !!v || "El nombre es requerido",
      (v) => !/^[0-9]*$/.test(v) || "Ingrese solo letras",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 caracteres"
    ],
    ageRules: [(v) => !!v || "Debes indicar la edad de tu mascota"],
    sexRules: [(v) => !!v || "Selecciona el sexo de tu mascota"],
    microchipRules: [
      (v) => !!v || "Tu mascota debe tener microchip para contratar el seguro",
    ],
    selectedSpecies: null,
    breeds: [],
    speciesOptions: [
      {
        id: "dog",
        name: "Perro",
        image: dogIcon2,
      },
      {
        id: "cat",
        name: "Gato",
        image: catIcon2,
      },
    ],
    ages: [],
    loadingAges: false,
  }),
  mounted() {
    this.getAges();
    this.stepId = localStorage.getItem('stepId')
    if (this.screen > 800) {
      this.mascotaVista = 'mascota'
    } else {
      this.mascotaVista = 'mascota2'
    }
  },
  methods: {
    back() {
      this.$emit("back");
    },
    message() {
      this.$alert("El chip para tu mascota y posterior registro en www.registratumascota.cl, es un requisito para la ley N°21020 de Tenencia Responsable o Ley Cholito. No olvides realizar este proceso.", "Información", "info")
    },
    validate() {
      if (this.$refs.form.validate()) {
        localStorage.setItem("species", this.petData.species.id);
        //localStorage.setItem("breed", this.petData.breed);
        localStorage.setItem("namepet", this.petData.name);
        localStorage.setItem("ageQ", this.petData.age.quantity);
        localStorage.setItem("ageU", this.petData.age.unit);
        localStorage.setItem("ageLabel", this.petData.age.label);
        localStorage.setItem("sex", this.petData.sex.id);
        localStorage.setItem("sexLabel", this.petData.sex.label);

        //Nuevas
        localStorage.setItem("petSex", this.petData.sex.petSex);
        localStorage.setItem("breedID", this.petData.breed.id);
        localStorage.setItem("breedName", this.petData.breed.name);
        localStorage.setItem("ageCode", this.petData.age.code);

        if (this.screen > 800) {
          window.location.pathname = "/planEscritorio";
        } else {
          window.location.pathname = "/planMovil";
        }
      }
    },
    async getBreeds() {
      if (this.petData.species) {
        this.petData.breed = null;
        const data = await BreedsService.getBreeds(this.petData.species.id);

        // Transforma la estructura a un array plano para el v-select
        this.breeds = data.flatMap(category => category);

        if (this.petData.species.id == 'dog') {
          this.speciesOptions[0].image = dogIcon
          this.speciesOptions[1].image = catIcon2
          this.petSelect = false
          this.catSelect = true
        } else if (this.petData.species.id == 'cat') {
          this.speciesOptions[0].image = dogIcon2
          this.speciesOptions[1].image = catIcon
          this.petSelect = true
          this.catSelect = false
        }
      }
    },
    async getAges() {
      this.loadingAges = true;
      this.ages = await AgeService.getAges();
      this.loadingAges = false;
    },
    async change() {
      var values;
      try {
        values = [
          this.petData.species.name,
          this.petData.breed,
          this.petData.breed.id,
          this.petData.breed.name,
          this.petData.name,
          this.petData.age == null ? '' : this.petData.age.label,
          this.petData.sex == null ? '' : this.petData.sex.label,
          this.petData.hasMicrochip,
        ]
        var carro = await ShieldService.createStep(values, 3, 0, this.stepId)
        this.stepId = carro.data.data._id
      } catch (error) {
        console.log("Cart Fail");
      }
    },
  }
};

</script>
<style lang="scss">
.mascota {
  width: 100%;
  margin-left: 2%;
  color: white;
}

.mascota2 {
  width: 100%;
  margin-left: -6%;
  color: white;
}

.v-textField {
box-sizing: border-box;
min-height: 57px; 
margin: auto; 
background: #FFFFFF; 
border: 1.5px solid rgba(142, 142, 142, 0.2);
box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08); 
border-radius: 6px;
}

.form-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.species-container {
  display: block;
  position: relative;
  text-align: center;
  // margin-bottom: 2rem;
  cursor: pointer;

  .species-selector {
    display: inline-block;
    position: relative;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    // padding: 0.5rem 1rem;
    transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    // border: 1px solid #f5f5f5;
    cursor: pointer;

    .radio-input {
      padding: 0.5rem;
      display: flex;
      flex-direction: column;
      cursor: pointer;
    }

    &:hover {
      background-color: #f5f5f5;
    }

    &.active {
      background-color: #f5f5f5;
      box-shadow: 4px 4px 4px #c6c6c6;
    }

    input[type="radio"] {
      position: absolute;
      left: 0;
      opacity: 0;
      outline: none;
      z-index: -1;
    }

    .species-icon {
      height: 100px;
      cursor: pointer;
    }
  }
}

.v-col {
  text-align: left;
}

.v-input--radio-group--column .v-input--radio-group__input {
  flex-direction: row !important;
}

@media (max-width: 800px) {
  .form-container {
    flex-direction: column;
    align-items: center;
  }
}

html body div#app.v-application.v-application--is-ltr.theme--light div.v-application--wrap main.v-main div.v-main__wrap div.container header div div.desktop-header div.desktop-logo-container div a.router-link-active div i.v-icon.notranslate.mdi.mdi-chevron-left.theme--light {
  color: #002C77 !important;
}

</style>