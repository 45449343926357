<template>
  <div>

    <v-row justify="center">
      <v-dialog v-model="dialog" width="430px">
        <v-card>
          <v-card-text>
            <div class="text-center">

              <br>
              <br>
              <label style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 18px; line-height: 140%; letter-spacing: 0.02em;
                  color: black; flex: none; order: 1; flex-grow: 0;">Estas son las coberturas y
                protecciones del</label>
              <br>
              <br>
              <label style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 18px; line-height: 140%; letter-spacing: 0.02em;
                  color: black; flex: none; order: 1; flex-grow: 0;">Plan <label style="font-family: Helvetica; font-style: normal; font-weight: 600; 
                  font-size: 20px; line-height: 140%; letter-spacing: 0.02em;
                  color: rgba(0, 44, 119, 1); flex: none; order: 1; flex-grow: 0; text-transform: uppercase;">{{ info[0][indexInfo]
                  }}</label> de
                Mascotas</label>
              <br>
              <br>
              <label class="text-center encabezado">Libre elección y sin deducible</label>
              <br>
              <br>
              <label class="texto">Puedes llevar a tu mascota a la
                clínica veterinaria <label class="textoAzul">que tú quieras</label>, sin restricciones. Además, todas
                nuestras coberturas son <label class="textoAzul">sin
                  deducible</label>.</label>
              <br>
              <br>
              <label class="text-center encabezado">Urgencias Médicas</label>
              <br>
              <br>
              <label class="texto">Te devolveremos hasta el <label class="textoAzul">{{ info[1][indexInfo] }}</label> de
                tus
                gastos frente a urgencias veterinarias sin deducibles en cualquier clínica. <label class="textoAzul">Tope
                  UF {{ info[2][indexInfo] }}</label>.</label>
              <br>
              <br>
              <label class="texto">Puedes usarlo a los <label class="textoAzul">15 días</label> de
                contratación y hasta <label class="textoAzul">{{ info[3][indexInfo] }} veces al año</label>.</label>
              <br>
              <br>
              <label class="text-center encabezado">Consultas Médicas</label>
              <br>
              <br>
              <label class="texto">Reembolsamos las consultas médicas y
                controles de tu mascota con el Veterinario que tú quieras. <label class="textoAzul">Tope UF
                  {{ info[4][indexInfo] }}</label>.</label>
              <br>
              <br>
              <label class="texto">Puedes usarlo a los <label class="textoAzul">15 días</label> de
                contratación y <label class="textoAzul">{{ info[5][indexInfo] }} vez al año</label>.</label>
              <br>
              <br>
              <label class="text-center encabezado">Medicamentos</label>
              <br>
              <br>
              <label class="texto">¿Le recetaron remedios a tu mascota?
                Te damos hasta <label class="textoAzul">$10.000 al mes</label> en reembolsos para medicamentos
                veterinarios con receta.</label>
              <br>
              <br>
              <label class="texto">Puedes usarlo a los <label class="textoAzul">30 días</label> de
                contratación y <label class="textoAzul">1 vez al mes</label>.</label>
              <br>
              <br>
              <label class="text-center encabezado">Vacuna Antirrábica</label>
              <br>
              <br>
              <label class="texto">Te reembolsamos <label class="textoAzul">UF 0,5</label> en el pago de
                la vacuna de tu mascota en cualquier clínica.</label>
              <br>
              <br>
              <label class="texto">Puedes usarlo a los <label class="textoAzul">30 días</label> de
                contratación y <label class="textoAzul">1 vez al año</label>.</label>
              <br>
              <br>
              <label class="text-center encabezado">Responsabilidad Civil</label>
              <br>
              <br>
              <label class="texto">Tu mascota se portó mal y mordió o
                hizo algún daño a otra persona, te ayudamos en caso de demanda con <label class="textoAzul">UF {{
                  info[8][indexInfo] }}
                </label> si tienes que pagar por los
                daños.</label>
              <br>
              <br>
              <label class="texto">Puedes usarlo a los <label class="textoAzul">0 días</label> de
                contratación y <label class="textoAzul">1 vez al año</label>.</label>
              <br>
              <br>
              <label class="text-center encabezado">Telemedicina Veterinaria</label>
              <br>
              <br>
              <label class="texto">¿Tienes dudas de salud de tu mascota?
                Deja que nuestros expertos te ayuden llamando sin costo a la hora que necesites.</label>
              <br>
              <br>
              <label class="texto">Puedes usarlo a los <label class="textoAzul">15 días</label> de
                contratación y hasta <label class="textoAzul">{{ info[6][indexInfo] }} veces al año</label>.</label>
              <br>
              <br>
              <label class="text-center encabezado">Asesoría Legal Teléfonica</label>
              <br>
              <br>
              <label class="texto">¿Necesitas ayuda con temas legales
                por algo de tu mascota? Deja que nuestros expertos te ayuden llamando sin costo a la hora que
                necesites.</label>
              <br>
              <br>
              <label class="texto">Puedes usarlo a los <label class="textoAzul">15 días</label> de
                contratación y hasta <label class="textoAzul">{{ info[7][indexInfo] }} veces al año</label>.</label>
              <br>

            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn style="box-sizing: border-box; padding: 12px 24px; gap: 10px; background: #FFB600; border: 2px solid #FFB600;
                    border-radius: 8px; margin-top: -6%; font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 14px; line-height: 17px;
                    letter-spacing: 0.02em; color: white; flex: none; order: 0; flex-grow: 0; margin:auto;"
              elevation="0" text @click="dialog = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <div v-if="planData.name == 'Mascota Senior' && tipoPlan == true">
      <div class="plan">
        <v-col class="text-center" style="margin-bottom: 160px;">
          <br>
          <br>
          <br><br>
          <label class="frame" style="font-family: Helvetica; font-style: normal; line-height: 28px;
                           color: #000000; flex: none; order: 0; flex-grow: 0; font-weight: bold;">Recomendado</label>
          <br>

          <label class="text-center" style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 25px; line-height: 28px;
              color: #FFFFFF; flex: none; order: 0; flex-grow: 0;">{{ planData.name }}</label>
          <br>


          <label class="text-center" style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 15px; line-height: 28px;
              color: #FFFFFF; flex: none; order: 0; flex-grow: 0;">Libre elección</label>
          <br>

          <label class="text-center" style="color: white; font-size:32px">{{ formatPrice(planData.priceCLP) }}</label>
          <br>
          <label class="text-center" style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 10px; line-height: 15px;
                color: rgba(255, 255, 255, 0.8); flex: none; order: 1; flex-grow: 0;">UF {{ (planData.values[0].price).toFixed(2)
                }}
            mensual</label>

          <br>

          <hr
            style="margin: auto; border: 0.946207px solid #FFFFFF; flex: none; order: 1; flex-grow: 0; width: 250px; height: 0px;">

          <br>

          <li style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 10px; line-height: 140%; letter-spacing: 0.02em;
                color: #FFFFFF; flex: none; order: 1; flex-grow: 0; text-align: left; margin-left: 10%;">
            Telemedicina Veterinaria <p
              style="text-align: left; font-family: Helvetica; font-style: normal; 
                font-weight: 400; font-size: 10px; line-height: 140%; letter-spacing: 0.02em; color: #FFFFFF; flex: none; order: 1; flex-grow: 0; text-align: left; margin-left: -16%;">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Sin Límite)
            </p>
          </li>

          <li style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 10px; line-height: 140%; letter-spacing: 0.02em;
                color: #FFFFFF; flex: none; order: 1; flex-grow: 0; text-align: left; margin-left: 10%;"> {{
                  planData.coverage[1].description }} <p
              style="text-align: left; font-family: Helvetica; font-style: normal; 
                font-weight: 400; font-size: 10px; line-height: 140%; letter-spacing: 0.02em; color: #FFFFFF; flex: none; order: 1; flex-grow: 0; text-align: left; margin-left: -16%;">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{
                planData.coverage[1].amount }} UF
            </p>
          </li>

          <li style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 10px; line-height: 140%; letter-spacing: 0.02em;
                color: #FFFFFF; flex: none; order: 1; flex-grow: 0; text-align: left; margin-left: 10%;"> {{
                  planData.coverage[2].description }}<p></p>
          </li>

          <li style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 10px; line-height: 140%; letter-spacing: 0.02em;
                color: #FFFFFF; flex: none; order: 1; flex-grow: 0; text-align: left; margin-left: 10%;"> {{
                  planData.coverage[3].description }}<p></p>
          </li>

          <li style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 10px; line-height: 140%; letter-spacing: 0.02em;
                color: #FFFFFF; flex: none; order: 1; flex-grow: 0; text-align: left; margin-left: 10%;"> {{
                  planData.coverage[4].description }} <p
              style="text-align: left; font-family: Helvetica; font-style: normal; 
                font-weight: 400; font-size: 10px; line-height: 140%; letter-spacing: 0.02em; color: #FFFFFF; flex: none; order: 1; flex-grow: 0; text-align: left; margin-left: -16%;">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                planData.coverage[4].description2 }} {{ planData.coverage[4].amount }} UF
            </p>
          </li>

          <li style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 10px; line-height: 140%; letter-spacing: 0.02em;
                color: #FFFFFF; flex: none; order: 1; flex-grow: 0; text-align: left; margin-left: 10%;"> {{
                  planData.coverage[5].description }} {{ planData.coverage[5].amount }} UF<p></p>
          </li>

          <li style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 10px; line-height: 140%; letter-spacing: 0.02em;
                color: #FFFFFF; flex: none; order: 1; flex-grow: 0; text-align: left; margin-left: 10%;"> {{
                  planData.coverage[6].description }} <p
              style="text-align: left; font-family: Helvetica; font-style: normal; 
                font-weight: 400; font-size: 10px; line-height: 140%; letter-spacing: 0.02em; color: #FFFFFF; flex: none; order: 1; flex-grow: 0; text-align: left; margin-left: -16%;">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                planData.coverage[6].description2 }} {{ formatPrice(planData.coverage[6].amount) }} CLP
            </p>
          </li>


          <hr
            style="margin: auto; border: 0.946207px solid #FFFFFF; flex: none; order: 1; flex-grow: 0; width: 250px; height: 0px;">
          <br><u class="pointer" style="box-sizing: border-box; padding: 12px 24px; gap: 10px; 
        border-radius: 8px; margin-top: -6%; font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 14px; line-height: 17px;
        letter-spacing: 0.02em; color: white; flex: none; order: 0; flex-grow: 0; width: 300px;" elevation="0"
            @click="Info(index)">
            VER DETALLE
          </u><br>
          <div class="plan-info">

            <v-btn style="box-sizing: border-box; display: flex;flex-direction: row; justify-content: center; align-items: center; padding: 13px 24px;
                  gap: 10px; position: absolute; height: 39px; background: #FFB600;
                  border: 2px solid #FFB600; border-radius: 8px; margin-left: 1%;" elevation="0" @click="validate()">
              CONTINUAR
            </v-btn>
          </div>

        </v-col>

        <br>



      </div>

    </div>

    <div v-if="planData.name == 'Plan M' && tipoPlan == false">

      <div class="plan">

        <v-col class="text-center" style="margin-bottom: 160px;">
          <br>
          <br>
          <br><br>
          <label class="frame" style="font-family: Helvetica; font-style: normal; line-height: 28px;
                    color: #000000; flex: none; order: 0; flex-grow: 0; font-weight: bold;">Recomendado</label>
          <br>
          <label class="text-center" style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 25px; line-height: 28px;
                    color: #FFFFFF; flex: none; order: 0; flex-grow: 0;">{{ planData.name }}</label>

          <br>
          <label class="text-center" style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 15px; line-height: 28px;
                    color: #FFFFFF; flex: none; order: 0; flex-grow: 0;">Libre elección</label>
          <br>
          <label class="text-center" style="color: white; font-size:32px">{{ formatPrice(planData.priceCLP) }}</label>
          <br>
          <label class="text-center" style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 10px; line-height: 15px;
                      color: rgba(255, 255, 255, 0.8); flex: none; order: 1; flex-grow: 0;">UF {{
                        (planData.values[0].price).toFixed(2)
                      }}
            mensual</label>
          <br>
          <hr
            style="margin: auto; border: 0.946207px solid #FFFFFF; flex: none; order: 1; flex-grow: 0; width: 250px; height: 0px;">
          <br>
          <li style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 10px; line-height: 140%; letter-spacing: 0.02em;
                      color: #FFFFFF; flex: none; order: 1; flex-grow: 0; text-align: left; margin-left: 10%;">
            Telemedicina Veterinaria <p
              style="text-align: left; font-family: Helvetica; font-style: normal; 
                      font-weight: 400; font-size: 10px; line-height: 140%; letter-spacing: 0.02em; color: #FFFFFF; flex: none; order: 1; flex-grow: 0; text-align: left; margin-left: -16%;">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Sin Límite)
            </p>
          </li>
          <li style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 10px; line-height: 140%; letter-spacing: 0.02em;
                      color: #FFFFFF; flex: none; order: 1; flex-grow: 0; text-align: left; margin-left: 10%;"> {{
                        planData.coverage[1].description }} <p
              style="text-align: left; font-family: Helvetica; font-style: normal; 
                      font-weight: 400; font-size: 10px; line-height: 140%; letter-spacing: 0.02em; color: #FFFFFF; flex: none; order: 1; flex-grow: 0; text-align: left; margin-left: -16%;">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{
                planData.coverage[1].amount }} UF
            </p>
          </li>
          <li style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 10px; line-height: 140%; letter-spacing: 0.02em;
                      color: #FFFFFF; flex: none; order: 1; flex-grow: 0; text-align: left; margin-left: 10%;"> {{
                        planData.coverage[2].description }}<p></p>
          </li>
          <li style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 10px; line-height: 140%; letter-spacing: 0.02em;
                      color: #FFFFFF; flex: none; order: 1; flex-grow: 0; text-align: left; margin-left: 10%;"> {{
                        planData.coverage[3].description }}<p></p>
          </li>
          <li style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 10px; line-height: 140%; letter-spacing: 0.02em;
                      color: #FFFFFF; flex: none; order: 1; flex-grow: 0; text-align: left; margin-left: 10%;"> {{
                        planData.coverage[4].description }} <p
              style="text-align: left; font-family: Helvetica; font-style: normal; 
                      font-weight: 400; font-size: 10px; line-height: 140%; letter-spacing: 0.02em; color: #FFFFFF; flex: none; order: 1; flex-grow: 0; text-align: left; margin-left: -16%;">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                planData.coverage[4].description2 }} {{ planData.coverage[4].amount }} UF
            </p>
          </li>
          <li style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 10px; line-height: 140%; letter-spacing: 0.02em;
                      color: #FFFFFF; flex: none; order: 1; flex-grow: 0; text-align: left; margin-left: 10%;"> {{
                        planData.coverage[5].description }} {{ planData.coverage[5].amount }} UF<p></p>
          </li>
          <li style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 10px; line-height: 140%; letter-spacing: 0.02em;
                      color: #FFFFFF; flex: none; order: 1; flex-grow: 0; text-align: left; margin-left: 10%;"> {{
                        planData.coverage[6].description }} <p
              style="text-align: left; font-family: Helvetica; font-style: normal; 
                      font-weight: 400; font-size: 10px; line-height: 140%; letter-spacing: 0.02em; color: #FFFFFF; flex: none; order: 1; flex-grow: 0; text-align: left; margin-left: -16%;">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                planData.coverage[6].description2 }} {{ formatPrice(planData.coverage[6].amount) }} CLP
            </p>
          </li>
          <hr
            style="margin: auto; border: 0.946207px solid #FFFFFF; flex: none; order: 1; flex-grow: 0; width: 250px; height: 0px;">
          <br><u class="pointer" style="color:#FFFFFF;" @click="Info(index)">VER DETALLE</u><br>
          <div class="plan-info">
            <v-btn style="box-sizing: border-box; display: flex;flex-direction: row; justify-content: center; align-items: center; padding: 13px 24px;
                  gap: 10px; position: absolute; height: 39px; background: #FFB600;
                  border: 2px solid #FFB600; border-radius: 8px; " elevation="0" @click="validate()">
              CONTINUAR
            </v-btn>
          </div>
        </v-col>
        <br>
      </div>

    </div>

    <div v-if="planData.name != 'Plan M' && tipoPlan == false" class="plan2">

      <v-col class="text-center">


        <div v-if="index == 0">


        </div>

        <div v-if="index != 0">


        </div>

        <label class="text-center" style="color: #5A5A5A; font-size:25px">{{ planData.name }}</label>
        <br>
        <label class="text-center" style="color: #5A5A5A; font-size:15px">Libre elección</label>
        <br>
        <label class="text-center" style="color: rgba(0, 44, 119, 1); font-size:32px">{{ formatPrice(planData.priceCLP) }}</label>
        <br>
        <label class="text-center" style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 10px; line-height: 15px;
                    color: rgba(0, 44, 119, 1); flex: none; order: 1; flex-grow: 0;">UF {{ (planData.values[0].price).toFixed(2) }}
          mensual</label>
        <br>
        <hr
          style="margin: auto; border: 1px solid rgba(0, 0, 0, 0.3); flex: none; order: 1; flex-grow: 0; width: 250px; height: 0px;">
        <br>
        <li
          style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 10px; line-height: 140%; letter-spacing: 0.02em;
                    color: rgba(0, 0, 0, 0.5); flex: none; order: 1; flex-grow: 0; text-align: left; margin-left: 10%;">
          Telemedicina Veterinaria
          <p
            style="text-align: left; font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 10px; line-height: 140%; letter-spacing: 0.02em;
                    color: rgba(0, 0, 0, 0.5); flex: none; order: 1; flex-grow: 0; text-align: left; margin-left: -15%;">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Sin
            Límite)</p>
        </li>

        <li
          style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 10px; line-height: 140%; letter-spacing: 0.02em; 
                    color: rgba(0, 0, 0, 0.5); flex: none; order: 1; flex-grow: 0; text-align: left; margin-left: 10%;">
          {{
            planData.coverage[1].description }}
          <p
            style="text-align: left; font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 10px; line-height: 140%; letter-spacing: 0.02em;
                    color: rgba(0, 0, 0, 0.5); flex: none; order: 1; flex-grow: 0; text-align: left; margin-left: -15%;">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
              planData.coverage[1].amount }} UF</p>
        </li>

        <li
          style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 10px; line-height: 140%; letter-spacing: 0.02em;
                    color: rgba(0, 0, 0, 0.5); flex: none; order: 1; flex-grow: 0; text-align: left; margin-left: 10%;">
          {{
            planData.coverage[2].description }}<p></p>
        </li>

        <li
          style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 10px; line-height: 140%; letter-spacing: 0.02em;
                    color: rgba(0, 0, 0, 0.5); flex: none; order: 1; flex-grow: 0; text-align: left; margin-left: 10%;">
          {{
            planData.coverage[3].description }}<p></p>
        </li>

        <li
          style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 10px; line-height: 140%; letter-spacing: 0.02em;
                    color: rgba(0, 0, 0, 0.5); flex: none; order: 1; flex-grow: 0; text-align: left; margin-left: 10%;">
          {{
            planData.coverage[4].description }}
          <p
            style="text-align: left; font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 10px; line-height: 140%; letter-spacing: 0.02em;
                    color: rgba(0, 0, 0, 0.5); flex: none; order: 1; flex-grow: 0; text-align: left; margin-left: -15%;">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
              planData.coverage[4].description2 }} {{ planData.coverage[4].amount }} UF</p>
        </li>

        <li v-if="index != 0"
          style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 10px; line-height: 140%; letter-spacing: 0.02em; 
                    color: rgba(0, 0, 0, 0.5); flex: none; order: 1; flex-grow: 0; text-align: left; margin-left: 10%;">
          {{
            planData.coverage[5].description }} {{ planData.coverage[5].amount }} UF <p></p>
        </li>

        <li
          style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 10px; line-height: 140%; letter-spacing: 0.02em;
                    color: rgba(0, 0, 0, 0.5); flex: none; order: 1; flex-grow: 0; text-align: left; margin-left: 10%;">
          {{
            planData.coverage[6].description }}
          <p
            style="text-align: left; font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 10px; line-height: 140%; letter-spacing: 0.02em;
                    color: rgba(0, 0, 0, 0.5); flex: none; order: 1; flex-grow: 0; text-align: left; margin-left: -15%;">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
              planData.coverage[6].description2 }} {{ formatPrice(planData.coverage[6].amount) }} CLP </p>
        </li>

        <hr
          style="margin: auto; border: 1px solid rgba(0, 0, 0, 0.3); flex: none; order: 1; flex-grow: 0; width: 250px; height: 0px;">

        <div v-if="index == 0">
          <br>
        </div>

        <br><u class="pointer" @click="Info(index)" style="color:#002C77;">VER DETALLE</u><br>

        <div class="plan-info">
          <v-btn
            style="box-sizing: border-box; display: flex; flex-direction: row; justify-content: center; align-items: center; padding: 13px 24px;
                          gap: 10px; width: 214px; height: 39px; background: #FFB600; border-radius: 8px; flex: none; order: 8; flex-grow: 0; color: #002C77; margin-top: -1%;"
            elevation="0" @click="validate()">
            CONTINUAR
          </v-btn>
        </div>
      </v-col>
    </div>
  </div>
</template>

<script>
import ShieldService from "../services/shield.service";

export default {
  name: "Plan",
  props: ["planData", "planSenior", "index", "couponId", "tipoPlan"],
  data: () => ({
    stepId: null,
    formatter: new Intl.NumberFormat("es-CL", {
      style: "currency",
      currency: "CLP",
    }),
    info: [['Plan S',  'Plan M', 'Plan L', "Mascota Senior"], ['40%', '60%','80%', '80%'], ['50', '100', '250', '250'], ['2', '2', '3', '3'], ['1', '2', '3', '3'], ['1', '1', '2', '2'], ['3', '4', '6', '6'], ['2', '2', '4', '4'], ['5', '10', '15', '15']],
    indexInfo: '0',
    dialog: false,
  }),
  methods: {
    Info(index) {
      if (this.planData.name == 'Mascota Senior') {
        this.indexInfo = 3
      } else {
        this.indexInfo = index
      }

      this.dialog = true
    },
    formatPrice(number) {
      return this.formatter.format(number);
    },
    ufchange(uf) {
      let nn = uf.toString();
      let new_uf = nn.replace('.', ',')
      return new_uf;
    },
    async validate() {
      localStorage.setItem("planId", this.planData._id);
      localStorage.setItem("couponId", this.couponId);

      var values;
      try {
        values = {
          plan: this.planData
        }
        var carro = await ShieldService.createStep(values, 2, 0, this.stepId)
        this.stepId = carro.data.data._id
      } catch (error) {
        console.log("Cart Fail");
      }

      window.location.pathname = "/datos";
    },
    parseNumber(n) {
      return new Intl.NumberFormat().format(n);
    },
  },
  mounted() {
    this.stepId = localStorage.getItem('stepId')
    console.log(this.tipoPlan)
    console.log(this.planData)
  },
};
</script>

<style lang="scss" scoped>
.texto {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: rgba(0, 0, 0, 0.5);
  flex: none;
  order: 1;
  flex-grow: 0;
}

.textoAzul {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400px;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: rgba(0, 44, 119, 1);
  flex: none;
  order: 1;
  flex-grow: 0;
}

.pointer {
  cursor: pointer;
}

.encabezado {
  color: rgba(0, 44, 119, 1);

  font-size: 18px;
  font-weight: 600;
}

.plan-info {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.frame {
  background: #FFB600;
  width: 102px;
  height: 17px;
  padding: 3px 15px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 17px;
  border-radius: 4px;
  /* identical to box height */

  letter-spacing: 0.5px;
}

.frame2 {
  background: #FFB600;
  width: 102px;
  height: 17px;
  padding: 3px 15px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 17px;
  border-radius: 4px;
  font-family: Helvetica;
  line-height: 28px;
  color: #000000;
  font-weight: bold;
}

.plan {
  background: linear-gradient(0deg, #0077A0, #0077A0), #D8D8D8;
  border-radius: 10px;

  border: 1px solid #DEE1E6;
  box-shadow: 0px 24px 34px rgba(0, 0, 0, 0.03);
  border-radius: 15px;
  background-position: center;
  background-size: cover;
  width: 270px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.75rem;
  height: 590px;
  float: left;
  margin-right: 15px;
}

.plan2 {
  background-color: #ffffff;
  border: 1px solid #DEE1E6;
  box-shadow: 0px 24px 34px rgba(0, 0, 0, 0.03);
  border-radius: 15px;
  background-position: center;
  background-size: cover;
  width: 270px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.75rem;
  height: 590px;
  float: left;
  margin-right: 15px;
}

html body div#app.v-application.v-application--is-ltr.theme--light div.v-application--wrap main.v-main div.v-main__wrap div.container header div div.desktop-header div.desktop-logo-container div a div i.v-icon.notranslate.mdi.mdi-chevron-left.theme--light {
  color: #002C77 !important;
}

</style>